pageLoad('.custom_question_options', () => {
  $('.add-spend').on('click', ({ currentTarget }) => {
    const type = $(currentTarget).data('type');
    const $row = $('.spends').children().last();

    $row
      .find(`.col-auto[data-type]:not([data-type=${type}])`)
      .remove();

    $row.find('select').trigger('change');
  });

  $('.spends').on('change', 'select', ({ currentTarget }) => {
    const $row = $(currentTarget).closest('.row-outer');
    const name =
      $(currentTarget).find(`option[value=${currentTarget.value}]`).text();

    $row.find('input[type=text]').val(`Monthly ${name} Spend`);
  });
});
