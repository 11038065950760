pageLoad('.info_blocks', () => {
  $('#info_block_property_type')
    .on('change', ({ currentTarget }) => {
      const propertyType = currentTarget.value;

      $('[data-property_type]').each((_index, node) => {
        const $node = $(node);
        const value = $node.data('property_type');

        if (value === propertyType) {
          $node.show();
        } else {
          $node.hide();
          $node
            .find('input[type=checkbox]:checked')
            .prop('checked', false)
            .trigger('change');
        }
      });
    })
    .trigger('change');

  $('#info_block_apply_type')
    .on('change', ({ currentTarget }) => {
      const applyType = currentTarget.value;

      $('[data-apply_type]').each((_index, node) => {
        const $node = $(node);
        const value = $node.data('apply_type');

        if (value === applyType) {
          $node.show();
        } else {
          $node.hide();
          $node
            .find('input[type=checkbox]:checked')
            .prop('checked', false)
            .trigger('change');
        }
      });
    })
    .trigger('change');
});
