import URI from 'urijs';
import axios from '../helpers/axios';

pageLoad('reward_merchants_index', () => {
  $('.card').on('ajax:before', ({ target }) => {
    target.classList.add('ajax');
  });

  $('.card').on('ajax:success', async ({ target, currentTarget }) => {
    const plaidCategoryIds = $(target)
      .closest('tbody')
      .children('tr')
      .toArray()
      .map(node => $(node).data('reward_merchant_id'));

    target.classList.remove('ajax');
    currentTarget.classList.add('ajax');

    const { data } = await axios.get(
      URI(window.location.toString())
        .addSearch('reward_merchant_ids', plaidCategoryIds.join(','))
    );
    $('.card').html(data);

    currentTarget.classList.remove('ajax');
  });

  $('.card').on('click', '.add-category', ({ currentTarget }) => {
    $(currentTarget).next().removeClass('d-none');
    $(currentTarget).remove();
  });
});
