import fontawesome from '@fortawesome/fontawesome';

import axios from '../helpers/axios';

pageLoad('.reward_programs', () => {
  initCustomQuestions();
});

export function initCustomQuestions() {
  $('.add-custom_question').on('click', e => {
    e.preventDefault();

    const $form = $($('.custom_questions').data('template'))
      .appendTo($('.custom_questions'));

    $form.find('select').focus();

    fontawesome.dom.i2svg();
  });
  $('.custom_questions').on('click', '.remove-custom_question', e => {
    e.preventDefault();

    const $row = $(e.currentTarget).closest('tr');

    $row.hide();
    $row
      .find('input[type=hidden][name$="_destroy]"]')
      .val('1');
  });

  $('.custom_questions')
    .on('change', 'select.custom_question_id', async ({ currentTarget }) => {
      if (!currentTarget.value) { return; }

      const $container = $(currentTarget).closest('tr');
      const url = $container
        .data('url_template')
        .replace('ID', currentTarget.value);

      currentTarget.disabled = true;

      const { data } = await axios.get(url);
      $container.replaceWith(data);
    });

  $('.custom_questions')
    .on('change', 'input[type=checkbox]', ({ currentTarget }) => {
      $(currentTarget)
        .closest('.form-check')
        .find('input[name]')
        .val(currentTarget.checked ? 1 : 0);
    });
}
