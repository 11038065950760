pageLoad('.yodlee', () => {
  $('pre code').each((_index, node) => {
    window.hljs.highlightBlock(node);
  });

  const $fastlink = $('#fastlink');

  $fastlink.on('click', () => {
    window.fastlink.open({
      fastLinkURL: $fastlink.data('fast-link-url'),
      jwtToken: `Bearer ${$fastlink.data('jwt-token')}`,
      params: {},
      onSuccess(data) {
        console.log('onSuccess');
        console.log(data);
      },
      onError(data) {
        console.log('onError');
        console.log(data);
      },
      onExit(data) {
        console.log('onExit');
        console.log(data);
      },
      onEvent(data) {
        console.log('onEvent');
        console.log(data);
      }
    }, 'container_fastlink');
  });
});
