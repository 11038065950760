import autosize from 'autosize';
import delay from 'delay';
import Editor from '@toast-ui/editor';
import fontawesome from '@fortawesome/fontawesome';
import { flash } from 'shiki-utils'

import Pikaday from 'javascripts/vendors/pikaday';
import copyToClipboard from 'javascripts/helpers/clipboard';

$(document).on('turbolinks:load', async () => {
  $('.datepicker input').each((_, node) => {
    new Pikaday({
      field: node,
      firstDay: 0,
      format: 'MM/DD/YYYY',
      toString(date, _format) {
        // you should do formatting based on the passed format,
        // but we will just return 'D/M/YYYY' for simplicity
        // console.log(date);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${('0' + month).slice(-2)}/${('0' + day).slice(-2)}/${year}`;
      },
      parse(dateString, _format) {
        // dateString is the result of `toString` method
        // console.log(dateString);
        const parts = dateString.split('/');
        const day = parseInt(parts[1], 10);
        const month = parseInt(parts[0], 10) - 1;
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      }
    });
  });

  $('.symbols-counter').each((_, node) => {
    const $node = $(node);
    const $textarea = $node.find('textarea');

    $('<div class="symbols"></div>').prependTo($node);

    $node.find('textarea').on('keyup', updateSymbols);

    updateSymbols({ currentTarget: $textarea[0] });
  });

  $('.js-copy-to-clipboard').on('click', async ({ target }) => {
    const selector = $(target)
      .closest('button')
      .data('clipboard-content-selector');
    const node = document.querySelector(selector);

    await copyToClipboard(
      node.tagName === 'INPUT' ?
        node.value :
        node.innerText
    );
    flash.notice('Copied to clipboard');
  });

  $('.form-group.with-counter input')
    .on('change', ({ currentTarget }) => {
      const $root = $(currentTarget).closest('.form-group.with-counter');
      $root.find('.counter').html(
        `(${$root.find('input:checked').length})`
      );
    })
    .trigger('change');

  $('.mass-select').on('click', ({ currentTarget }) => {
    const $button = $(currentTarget);
    const ids = $button.data('ids');
    const type = $button.data('type');

    const selector = ids
      .map(id => `[id$='_${type}_${id}']`)
      .join(',');

    const $checkboxes = $button.parent().find(selector);

    const isChecked = $checkboxes.toArray().every(node => node.checked);

    $checkboxes.prop('checked', !isChecked);
    $checkboxes.last().trigger('change');
  });

  ['perk', 'reward_program', 'spend', 'reward_program_merchant'].forEach(type => {
    $(`.add-${type}`).on('click', e => {
      e.preventDefault();

      const $node = $($(`.${type}s`).data('template')).appendTo($(`.${type}s`));

      $node
        .find('select')
        .trigger('change');

      $node
        .find('input.form-control.string')
        .first()
        .focus();

      fontawesome.dom.i2svg();
    });

    $(`.${type}s`).on('click', `.remove-${type}`, e => {
      e.preventDefault();

      const $row = $(e.currentTarget).closest('.row-outer');

      $row.hide();

      if (type === 'reward_program_merchant') {
        $row.next('.overrided_cards_block').hide();
      }

      $row
        .find('input[type=hidden][name$="_destroy]"]')
        .val('1');
    });
  });

  autosize($('.js-autoresize textarea'));
  // need to manually trigger update so textareas could obtain correct height
  await delay(600);
  $('.js-autoresize textarea').each((_index, node) => autosize.update(node));

  $('.toastui-wrapper').each((_index, node) => {
    const editor = new Editor({
      el: node.querySelector('.editor-container'),
      height: node.getAttribute('data-height'),
      initialValue: node.querySelector('input').value,
      frontMatter: true,
      autofocus: false,
      initialEditType: 'markdown',
      customHTMLRenderer: {
        paragraph(node, context) {
          if (node.lastChild.literal?.match(/^{:.*}$/)) {
            // context.skipChildren();
            // return [
            //   {
            //     type: 'openTag',
            //     tagName: 'p',
            //     attributes: {
            //       class: 'zxc'
            //     }
            //   },
            //   { type: 'html', content: context.getChildrenText(node) },
            //   { type: 'closeTag', tagName: 'p' }
            // ];
            return {
              outerNewLine: true,
              tagName: 'p',
              type: 'openTag',
              attributes: {
                class: 'd-none'
              }
            };
          }
          return context.origin(node, context);
        }
      },
      // previewStyle: 'vertical',
      usageStatistics: false,
      toolbarItems: [
        [
          // {
          //   el: (() => {
          //     // https://nhn.github.io/tui.editor/latest/tutorial-example15-customizing-toolbar-buttons
          //     const button = document.createElement('button');
          // 
          //     button.className = 'heading toastui-editor-toolbar-icons';
          //     button.addEventListener('click', (e) => {
          //       e.preventDefault();
          //       editor.exec('heading', { level: 2 });
          //     });
          // 
          //     return button;
          //   })(),
          //   // command: 'bold',
          //   tooltip: 'Heading'
          // },
          'heading',
          'bold', 'italic'/*, 'strike'*/
        ],
        ['hr', 'quote'],
        ['ul', 'ol', /*'task', */'indent', 'outdent'],
        [/*'table', */'image', 'link'],
        // ['code', 'codeblock'],
      ],
      hideModeSwitch:true
    });

    $(node).closest('form').on('submit', () => (
      node.querySelector('.form-group.hidden input').value = editor.getMarkdown()
    ));
  });
});


function updateSymbols({ currentTarget }) {
  const $textarea = $(currentTarget);
  const $node = $textarea.closest('.form-group');
  const symbols = $node.data('symbols');
  const { length } = $textarea.val();
  const text = symbols ?
    `${length} / ${symbols}` :
    length;

  $node
    .find('.symbols')
    .html(text);
}
