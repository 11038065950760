import fontawesome from '@fortawesome/fontawesome';
import faEdit from '@fortawesome/fontawesome-free-solid/faPencilAlt';
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus';
import faTrash from '@fortawesome/fontawesome-free-solid/faTrash';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/faTrashAlt';
import faSort from '@fortawesome/fontawesome-free-solid/faSort';
import faSortAmountUp from '@fortawesome/fontawesome-free-solid/faSortAmountUp';
import faSortAmountDown from '@fortawesome/fontawesome-free-solid/faSortAmountDown';
import faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle';
import faSignInAlt from '@fortawesome/fontawesome-free-solid/faSignInAlt';

fontawesome.library.add(faEdit);
fontawesome.library.add(faPlus);
fontawesome.library.add(faTrashAlt);
fontawesome.library.add(faTrash);
fontawesome.library.add(faSort);
fontawesome.library.add(faSortAmountUp);
fontawesome.library.add(faSortAmountDown);
fontawesome.library.add(faInfoCircle);
fontawesome.library.add(faSignInAlt);

$(document).on('turbolinks:load', () => {
  fontawesome.dom.i2svg();
});
$(document).on('ajax:success', () => {
  fontawesome.dom.i2svg();
});
