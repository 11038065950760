pageLoad(
  'widgets_show',
  'widgets_new',
  'widgets_create',
  'widgets_update',
  () => {
    $('.algo_options-add-spendings').on('click', addAlgoOptionsSpendings);
    $('.algo_options-container')
      .on('click', '.algo_options-remove-spendings', removeAlgoOptionsSpendings);
    $('.algo_options-spendings')
      .on(
        'change',
        'select[name="cms_widget[algo_options][raw_spendings][][category]"]',
        handleSpendingsChange
      );
  });

function addAlgoOptionsSpendings() {
  $('.algo_options-spendings').append(
    $('.algo_options-category-template').html()
  );
}

function removeAlgoOptionsSpendings({ currentTarget }) {
  $(currentTarget).closest('.form-group').remove();
}

function handleSpendingsChange({ currentTarget }) {
  const $select = $(currentTarget);
  const indx = $select.find('option:selected').data('z_index');
  const $zIndexInput = $select
    .closest('.form-group')
    .find('input[name="cms_widget[algo_options][raw_spendings][][z_index]"]');
  $zIndexInput.val(indx);
}
