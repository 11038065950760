import URI from 'urijs';
import delay from 'delay';
import axios from '../../helpers/axios';
import { initCustomQuestions } from '../reward_programs';

pageLoad(
  'reviews_edit',
  'reviews_new',
  'reviews_create',
  'reviews_update',
  'info_blocks_new',
  'info_blocks_create',
  'info_blocks_show',
  'info_blocks_update',
  'articles_new',
  'articles_create',
  'articles_show',
  'articles_update',
  'approval_odds_index',
  'approval_odds_save_all',
  () => {
    // store navigation so after submit we could return to appropriate section
    $('input[type=submit]').on('click', ({ currentTarget }) => {
      $('input[name=anchor]').val(currentTarget.name);
      const form = $(currentTarget).closest('form')[0];
      form.action = form.action.replace(/#.*|$/, `#${currentTarget.name}`);
    });

    $('.js-view-mode label').on('click', async ({ currentTarget }) => {
      const $root = $(currentTarget).closest('.js-view-mode');
      const field = $root.data('field');

      const mode = $root.find(`input[name="view_mode_${field}"]:checked`).val();
      const markdown = $root.find('textarea').val();
      const $contentWrapper = $root.find('.content-wrapper');
      const $previewWrapper = $root.find('.preview-wrapper');

      if (mode === 'preview') {
        $contentWrapper.hide();
        $previewWrapper.text('Preview...').show();

        const html = await fetchPreview(markdown);
        $previewWrapper.html(html);
      } else {
        $contentWrapper.show();
        $previewWrapper.hide();
      }
    });

    // NOTE: execCommand('copy') doesn't work here, so trying use the navigator.
    $('.js-copy-article-to-clipboard').on('click', async ({ currentTarget }) => {
      if (!navigator?.clipboard) {
        alert('We\'re sorry. Copy to clipboard doesn\'t work in your browser');
        return;
      }

      currentTarget.classList.add('ajax');

      const markdown = $('#cms_article_content')
        .toArray()
        .map(node => node.value)
        .join('\n\n')
        .trim();
      const html = await fetchPreview(markdown);
      await navigator.clipboard.writeText($(html).text());

      currentTarget.classList.remove('ajax');
    });

    $('.js-copy-markdown').on('click', async ({ currentTarget }) => {
      if (!navigator?.clipboard) {
        alert('We\'re sorry. Copy to clipboard doesn\'t work in your browser');
        return;
      }

      currentTarget.classList.add('ajax');

      await delay(250);
      await navigator.clipboard.writeText(currentTarget.previousElementSibling.textContent);

      currentTarget.classList.remove('ajax');
    });

    $('.js-search-image').on('click', async e => {
      e.preventDefault();
      const $target = $(e.target);
      $target.addClass('ajax');

      const articleId = $('input.search_image').data('article-id');
      const title = $('input.search_image').val();

      const { data } = await axios.get(
        '/cms/articles/search_images', { params: { title, article_id: articleId } }
      );
      $('.js-images').html(data);
      $target.removeClass('ajax');
    });

    $('.algo_options-add-spendings').on('click', addAlgoOptionsSpendings);
    $('.algo_options-container')
      .on('click', '.algo_options-remove-spendings', removeAlgoOptionsSpendings);

    // custom questions
    initCustomQuestions();
  });

pageLoad(
  'articles_new',
  'articles_create',
  'articles_show',
  'articles_update',
  () => {
    $('select.add-snippet_line').on('change', ({ currentTarget }) => {
      const snippetLineId = currentTarget.value;
      const snippetLineOptionNode = currentTarget
        .querySelector(`option[value='${snippetLineId}']`);
      const snippetLineTitle = snippetLineOptionNode.text;
      const snippetLineHtml = currentTarget
        .getAttribute('data-template')
        .replace(/%SNIPPET_LINE_ID%/g, snippetLineId)
        .replace(/%SNIPPET_LINE_TITLE%/g, snippetLineTitle);

      snippetLineOptionNode.remove();
      $('.cms_article_cms_snippet_line_ids').append(snippetLineHtml);

      if (currentTarget.childNodes.length === 1) {
        $(currentTarget).hide();
      }
    });

    $('select.add-pick').on('change', ({ currentTarget }) => {
      const pickId = currentTarget.value;
      const pickOptionNode = currentTarget
        .querySelector(`option[value='${pickId}']`);
      const pickTitle = pickOptionNode.text;
      const pickHtml = currentTarget
        .getAttribute('data-template')
        .replace(/%PICK_ID%/g, pickId)
        .replace(/%PICK_TITLE%/g, pickTitle);

      pickOptionNode.remove();
      $('.cms_article_picked_article_ids').append(pickHtml);

      if (currentTarget.childNodes.length === 1) {
        $(currentTarget).hide();
      }
    });
  });

async function fetchPreview(content) {
  return (await axios.get(
    URI('/cms/articles/preview').addSearch('content', content)
  )).data;
}

function addAlgoOptionsSpendings() {
  $('.algo_options-spendings').append(
    $('.algo_options-category-template').html()
  );
}

function removeAlgoOptionsSpendings({ currentTarget }) {
  $(currentTarget).closest('.form-group').remove();
}
