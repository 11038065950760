pageLoad('.issuer_rules', () => {
  $('#issuer_rule_mode')
    .on('change', ({ currentTarget }) => {
      const mode = currentTarget.value;

      $('[data-mode]').each((_index, node) => {
        const $node = $(node);
        const value = $node.data('mode');

        if (value.constructor === Array) {
          $node.toggle(value.includes(mode));
        } else {
          $node.toggle(value === mode);
        }
      });
    })
    .trigger('change');
});
