const INPUTS_SELECTOR = 'table tbody td:first-child .colorinput input';

pageLoad('cards_index', () => {
  toggleMassActions(false);

  const $selectAll = $('table thead th:first-child .colorinput input');
  const $selects = $(INPUTS_SELECTOR);

  $selectAll.on('change', ({ currentTarget }) => {
    $selects.prop('checked', currentTarget.checked);
    toggleMassActions(currentTarget.checked);
  });

  $selects.on('change', ({ currentTarget }) => {
    if (currentTarget.checked) {
      $selectAll.prop('checked', true);
    } else if (!$selects.filter(':checked').length) {
      $selectAll.prop('checked', false);
    }
    toggleMassActions($selectAll.prop('checked'));
  });

  $('.page-header .mass-action .cancel').on('click', e => {
    e.preventDefault();

    $selectAll
      .prop('checked', false)
      .trigger('change');
  });
});

function toggleMassActions(isChecked) {
  $('.page-header .mass-action').toggle(isChecked);
  $('.page-header .filters').toggle(!isChecked);

  $('.page-header .mass-action input[name=ids]').val(
    $(INPUTS_SELECTOR)
      .filter(':checked')
      .toArray()
      .map(node => node.value)
      .join(',')
  );
}
