/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

window.jQuery = jQuery;
window.$ = jQuery;

import Sugar from 'sugar'; // eslint-disable-line import/newline-after-import
Sugar.extend();

import 'tabler-ui/dist/assets/css/dashboard.css';
import 'bootstrap/dist/js/bootstrap';
import 'shiki-utils/flash.sass';

import 'pikaday/scss/pikaday.scss';

import 'jquery-ujs';

import pageLoad from '../javascripts/helpers/page_load'; // eslint-disable-line import/newline-after-import
window.pageLoad = pageLoad;

import pageUnload from '../javascripts/helpers/page_unload'; // eslint-disable-line import/newline-after-import
window.pageUnload = pageUnload;

import Turbolinks from 'turbolinks';

import csrf from '../javascripts/helpers/csrf';

window.axios = require('axios').create({
  headers: { ...csrf().headers, 'X-Requested-With': 'XMLHttpRequest' }
});

// const require_lib = require.context('../javascripts/lib', true);
// require_lib.keys().forEach(require_lib);

const requirePages = require.context('../javascripts/pages', true);
requirePages.keys().forEach(requirePages);

Turbolinks.start();
import bindings from '../javascripts/helpers/bindings';

import '../javascripts/helpers/font_awesome';

$(document).on(Object.keys(bindings).join(' '), e => {
  bindings[e.type].forEach(group => {
    let bodyClasses;
    if (group.conditions.length && (group.conditions[0][0] === '.')) {
      bodyClasses = group.conditions
        .filter(v => v[0] === '.')
        .map(v => `p-${v.slice(1)} `);
    } else {
      bodyClasses = [];
    }

    if (!group.conditions.length) {
      group.callback();
    } else if (bodyClasses && bodyClasses.some(v => document.body.className.indexOf(v) !== -1)) {
      group.callback();
    } else if (group.conditions.some(v => document.body.id === v)) {
      group.callback();
    }
  });
});

import '../javascripts/application';
import '../stylesheets/application.sass';
