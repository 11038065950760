pageLoad('.purchase_rewards', () => {
  $('#purchase_reward_units')
    .on('change', ({ currentTarget }) => {
      $('.unit-values span').hide();
      $(`.unit-values span[data-units=${currentTarget.value}`).show();

      $('.purchase_reward_threshold')
        .toggle(currentTarget.value === 'cash_back_dollars');
    })
    .trigger('change');

  $('.new-reward_merchant')
    .on('ajax:before', () => {
      $('#new_purcase_reward_modal .modal-html').html(
        '<div class="modal-body py-7 ajax"></div>'
      );
      showModal();
    })
    .on('ajax:success', (_e, data) => {
      updateModal(data);
    });
});

function showModal() {
  $('#new_purcase_reward_modal').modal({});
}

function updateModal(html) {
  const $form = $(html.replace(/card-/g, 'modal-')).find('form');
  $('#new_purcase_reward_modal .modal-content').html($form);

  $form.find('.modal-footer a').on('click', e => {
    e.preventDefault();
    hideModal();
  });

  $form
    .attr('data-remote', true)
    .on('ajax:before', ({ target }) => {
      target.classList.add('ajax');
    })
    .on('ajax:complete', ({ target }) => {
      target.classList.remove('ajax');
    })
    .on('ajax:success', (_e, data) => {
      if (data.constructor === Object) {
        addRow('reward_merchant', data);
        addRow('excluded_reward_merchant', data);
        hideModal();
      } else {
        updateModal(data);
      }
    });
}

function hideModal() {
  $('#new_purcase_reward_modal').modal('hide');
}

function addRow(type, { name, id }) {
  $(`.purchase_reward_${type}s`).append(`
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input check_boxes optional"
        type="checkbox"
        value="${id}"
        name="purchase_reward[${type}_ids][]"
        id="purchase_reward_${type}_ids_${id}"
      >
      <label
        class="custom-control-label collection_check_boxes"
        for="purchase_reward_${type}_ids_${id}"
      >${name}</label>
    </div>
  `);
}
