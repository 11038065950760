import Pikaday from 'javascripts/vendors/pikaday';
import moment from 'moment';
import { flash } from 'shiki-utils';

pageLoad('.mentioned_cards', () => {
  const dateRangeSelect = $('#date_range_select');
  const dateFromField = $('#date_from');
  const dateToField = $('#date_to');
  const cardsDropdown = $('.cards-multi-storage-select');
  const badge = document.querySelector('#card-count-badge');
  const checkboxes = document.querySelectorAll('.cards-multi-storage-select input[type=checkbox]');

  function updateBadgeCounter() {
    const checked = Array.from(checkboxes).filter(checkbox => checkbox.checked);
    badge.classList.toggle('d-none', checked.length === 0);
    badge.textContent = checked.length;
  }

  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('click', updateBadgeCounter);
  });

  cardsDropdown.find('.dropdown-toggle').on('click', () => {
    cardsDropdown.find('.dropdown-menu').toggleClass('show');
  });

  $(document).on('click', event => {
    if (!cardsDropdown.is(event.target) && cardsDropdown.has(event.target).length === 0) {
      cardsDropdown.find('.dropdown-menu').removeClass('show');
    }
  });

  const dateRanges = {
    today: {
      start: moment(),
      end: moment()
    },
    yesterday: {
      start: moment().subtract(1, 'days'),
      end: moment().subtract(1, 'days')
    },
    this_week: {
      start: moment().startOf('week'),
      end: moment().endOf('week')
    },
    last_week: {
      start: moment().subtract(1, 'week').startOf('week'),
      end: moment().subtract(1, 'week').endOf('week')
    },
    this_month: {
      start: moment().startOf('month'),
      end: moment().endOf('month')
    },
    last_month: {
      start: moment().subtract(1, 'month').startOf('month'),
      end: moment().subtract(1, 'month').endOf('month')
    },
    this_year: {
      start: moment().startOf('year'),
      end: moment().endOf('year')
    },
    last_year: {
      start: moment().subtract(1, 'year').startOf('year'),
      end: moment().subtract(1, 'year').endOf('year')
    }
  };

  const startDatePicker = new Pikaday({
    field: dateFromField[0],
    format: 'MM/DD/YYYY',
    onSelect(date) {
      if (moment(date).isValid()) {
        if (endDatePicker.getDate() && moment(endDatePicker.getDate()).isValid()) {
          updateDateFields(date, endDatePicker.getDate());
        } else {
          dateFromField.val(dateToString(date));
        }
      }
    }
  });

  const endDatePicker = new Pikaday({
    field: dateToField[0],
    format: 'MM/DD/YYYY',
    onSelect(date) {
      if (moment(date).isValid()) {
        if (startDatePicker.getDate() && moment(startDatePicker.getDate()).isValid()) {
          updateDateFields(startDatePicker.getDate(), date);
        } else {
          dateToField.val(dateToString(date));
        }
      }
    }
  });

  dateRangeSelect.on('change', function () {
    const selectedRange = $(this).val();
    const range = selectedRange.substring(selectedRange.indexOf('-') + 1);
    const { start, end } = dateRanges[range] || {};

    if (start && end) {
      updateDateFields(start, end);
    }
  });

  function dateToString(date) {
    return moment(date).format('MM/DD/YYYY');
  }

  function updateDateFields(startDate, endDate) {
    if (endDate && startDate && (endDate < startDate)) {
      flash.notice('End date can not be greater than start date');
      dateToField.val('');
      return;
    }
    dateFromField.val(dateToString(startDate));
    dateToField.val(dateToString(endDate));
  }
});
