import fontawesome from '@fortawesome/fontawesome';
import pluralize from 'pluralize';

import { initCustomQuestions } from './reward_programs';
import axios from '../helpers/axios';

pageLoad('.cards', () => {
  $('#card_apr_purchase_intro_disabled, #card_apr_transfer_intro_disabled')
    .on('change', ({ currentTarget }) => {
      $(`
        #${currentTarget.id.replace('_disabled', '')},
        #${currentTarget.id.replace('_disabled', '_months')}
      `)
        .prop('disabled', currentTarget.checked);
    })
    .trigger('change');

  $('.card-external_link').each((_index, node) => {
    const $link = $(node);
    const field = $link.data('field');

    $(`#card_${field}`)
      .on('change', () => {
        const site = $link.data('site');
        const card = $('#card_name').val();

        const url = $link
          .data('pattern')
          .replace('{site}', encodeURIComponent(site))
          .replace('{card}', encodeURIComponent(card));

        $link.attr('href', url);
      })
      .trigger('change');
  });

  $('#card_kind')
    .on('change', ({ currentTarget }) => {
      $('.js-rewards-area')
        .toggle(currentTarget.value !== 'no_rewards');
      $('.js-points_miles, .js-reward-program')
        .toggle(currentTarget.value === 'points_miles');
      $('.js-cash_back')
        .toggle(currentTarget.value === 'cash_back');
    })
    .trigger('change');

  $('#card_reward_program_id')
    .on('change', ({ currentTarget }) => {
      const $select = $(currentTarget);
      const $link = $select
        .parents('.js-reward-program')
        .find('.reward-program-external_link');

      $link.parent().toggle($select.val() !== '');
      $link.attr('href', $link.data('pattern').replace('{id}', $select.val()));
    })
    .trigger('change');

  $('#card_limit_type')
    .on('change', ({ currentTarget }) => (
      $('.form-fieldset.apr, .card_fee_transfer')
        .toggle(currentTarget.value !== 'charge')
    ))
    .trigger('change');

  $('.perks').on('change', '.select.new-perk', ({ currentTarget }) => {
    const $select = $(currentTarget);
    const $option = $select.find('option:selected');
    $select
      .closest('.row')
      .find('input[type=text][name$="name]"]')
      .val($option.text());
    $select
      .closest('.row')
      .find('input[type=number][name$="value]"]')
      .attr('placeholder', $option.data('default-value'));
    $select
      .closest('.row')
      .find('input[type=number][name$="required_spend]"]')
      .attr('placeholder', $option.data('default-required-spend'));
  });

  // is_first_year_only
  $('.perks').on('change', 'input[type=checkbox]', ({ currentTarget }) => {
    $(currentTarget)
      .closest('.form-check')
      .find('input[name]')
      .val(currentTarget.checked ? 1 : 0);
  });

  $('.js-show-diff')
    .on('ajax:before', () => {
      $('#show_diff_modal .modal-body').html(
        '<div class="modal-body py-7 ajax"></div>'
      );
      showModal();
    })
    .on('ajax:success', (_e, data) => {
      updateModal(data);
    });

  $('.js-mark-resolved')
    .on('ajax:before', ({ target }) => {
      target.classList.add('ajax');
    })
    .on('ajax:complete', ({ target }) => {
      target.classList.remove('ajax');
      $(target)
        .attr('href', '#')
        .text('Resolved')
        .addClass('disabled');
    });

  $('.exclude_card_id').on('change', ({ currentTarget }) => {
    axios.post($(currentTarget).data('url'));
    currentTarget.blur();
  });

  // custom questions
  initCustomQuestions();

  $('.info-block-modal').on('click', e => {
    e.preventDefault();
    const $modal = $('#info_block_modal');

    $modal
      .find('.modal-header')
      .html($(e.currentTarget).data('header'));
    $modal
      .find('.modal-body')
      .html(
        $(e.currentTarget).data('texts').map(v => `<p>${v}</p>`).join('')
      );

    $modal.modal({});
  });
});

function showModal() {
  $('#show_diff_modal').modal({});
}

function updateModal(html) {
  const $diff = $(html).find('.js-diff');
  $('#show_diff_modal .modal-body').html($diff);
}
