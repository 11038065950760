$(document).ready(() => {
  const resultInput = document.querySelector('#result-input');
  const bbcodeValuesDropdown = $('.bbcode-values-dropdown');

  let previousSelectedInfoCardOption = '';
  let previousSelectedCardOption = '';
  let previousSelectedGuidelineOption = '';
  let previousSelectedCategory = '';

  $('.bbcodes-dropdown').change(function () {
    const selectedCategory = $(this).val();

    if (selectedCategory === 'info_card') {
      const infoCardsData = JSON.parse($('#info-cards-data').val());
      assignPreviousSelectedOption(previousSelectedInfoCardOption);
      populateDropdownWithInfoCards(infoCardsData, previousSelectedInfoCardOption);
      bbcodeValuesDropdown.show();
    } else if (selectedCategory === 'compliance_text') {
      const guidelinesData = JSON.parse($('#guidelines').val());
      assignPreviousSelectedOption(previousSelectedGuidelineOption);
      populateDropdownWithGuidelines(guidelinesData, previousSelectedGuidelineOption);
      bbcodeValuesDropdown.show();
    } else {
      assignPreviousSelectedOption(previousSelectedCardOption);
      bbcodeValuesDropdown.show();

      const cardsData = JSON.parse($('#cards-data').val());
      populateDropdownWithCards(cardsData, previousSelectedCardOption);
    }

    if (selectedCategory === '') {
      bbcodeValuesDropdown.empty();
      resultInput.value = '';
    } else {
      const selectedValue = bbcodeValuesDropdown.val();
      populateResultInput(selectedCategory, selectedValue);
    }

    previousSelectedCategory = selectedCategory;
  });

  $('.bbcode-values-dropdown').change(function () {
    const selectedValue = $(this).val();
    const selectedCategory = $(this)
      .closest('.form-group')
      .find('.bbcodes-dropdown')
      .val();
    populateResultInput(selectedCategory, selectedValue);

    if (selectedCategory === 'info_card') {
      previousSelectedInfoCardOption = selectedValue;
    } else if (selectedCategory === 'compliance_text') {
      previousSelectedGuidelineOption = selectedValue;
    } else {
      previousSelectedCardOption = selectedValue;
    }
  });

  const assignPreviousSelectedOption = previousSelectedOption => {
    if (previousSelectedOption !== '') {
      bbcodeValuesDropdown.val(previousSelectedOption);
    } else {
      bbcodeValuesDropdown.val('');
    }
  };

  const populateResultInput = (selectedCategory, selectedValue) => {
    if (selectedCategory !== 'compliance_text') {
      resultInput.value = selectedValue ?
        `[${selectedCategory}=${selectedValue}]` :
        '';
    } else {
      resultInput.value = selectedValue ?
        `[${selectedCategory}=${selectedValue}]` :
        `[${previousSelectedCategory}=${selectedValue}]`;
    }
  };

  const populateDropdown = (
    dropdown,
    data,
    optionTextFn,
    optionValueFn,
    selectedOption
  ) => {
    dropdown.empty();

    data.forEach(item => {
      const optionText = optionTextFn(item);
      const optionValue = optionValueFn(item);

      const option = $('<option>', {
        value: optionValue,
        text: optionText
      });

      if (optionValue === selectedOption) {
        option.prop('selected', true);
      }

      dropdown.append(option);
    });

    if (selectedOption !== '') {
      dropdown.val(selectedOption);
    }
  };

  const populateDropdownWithInfoCards = (infoCardsData, selectedOption) => {
    populateDropdown(
      bbcodeValuesDropdown,
      infoCardsData,
      infoCard => infoCard[0],
      infoCard => infoCard[1],
      selectedOption
    );
  };

  const populateDropdownWithGuidelines = (guidelinesData, selectedOption) => {
    populateDropdown(
      bbcodeValuesDropdown,
      guidelinesData,
      guidelineID => guidelineID,
      guidelineID => guidelineID,
      selectedOption
    );
  };

  const populateDropdownWithCards = (cardsData, selectedOption) => {
    populateDropdown(
      bbcodeValuesDropdown,
      cardsData,
      card => card[0],
      card => card[1],
      selectedOption
    );
  };
});
