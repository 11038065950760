export default function (textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  }
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;

  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise((res, rej) => {
    // here the magic happens
    if (document.execCommand('copy')) {
      res();
    } else {
      rej();
    }
    textArea.remove();
  });
}
